<template>
  <div class="optionContent" :class="{'bgItem': settings && settings.renderItemsSideBySide}">
    <span class="indicator" v-if="settings && settings.showCarouselInMobile">({{runningNumber}}/{{total}})</span>
    <div v-html="option.content"></div>
  </div>
  <div class="answersOnRows" :class="{'bgItemPadding': settings && settings.renderItemsSideBySide}">
    <div
      v-for="(values, valueKey) in option.values"
      :key="valueKey"
      @click="checkboxClicked(option.id, values.value)"
      class="answer"
    >
      <div
        class="checkbox noselect"
        :class="{ selected: isSelected(option.id, values.value) }"
      ></div>
      <span>{{ values.content }}</span>
    </div>
  </div>
</template>
      
<script>
export default {
  props: ['option', 'settings', 'checkboxClicked', 'isSelected', 'total', 'runningNumber']
};
</script>

<style lang="scss" scoped>
@import '../tasks.scss';
.bgItem {
  background-color: var(--theme-color-fourth);
  padding: 30px;
}
.bgItemPadding {
  margin-top: 30px;
}
.indicator {
  float: right;
}

// .checkbox {
//     width: 35px;
//     height: 35px;
//     background: var(--theme-color-first);
//     display: inline-block;
//     cursor: pointer;

//     border: 1px solid var(--theme-color-second);
//     //color: ;
//     text-align: center;
//     vertical-align: middle;
//     line-height: 35px;
//     flex-shrink: 0;

//     &.selected {
//       /*background: green;*/
//       background: var(--theme-color-fourth);
//     }
// }
</style>